<template>
  <el-container>
    <!-- <div class="backgroundImg">
      <img src="../../assets/images/login/bg2.jpg" />
    </div> -->
    <el-main>
      <div class="bottom">
        <div class="left">
          <div class="backgroundImg">
            <img src="../../assets/images/login/2.jpg" />
          </div>
          <div class="content">
            <span class="title">欢迎</span>
            <span> 已有账号? </span>
            <el-button
              @click="
                isRight = false;
                is = 'userLogin';
              "
              >去登陆</el-button
            >
          </div>
        </div>
        <div class="right">
          <div class="backgroundImg">
            <img src="../../assets/images/login/1.jpg" />
          </div>
          <div class="content">
            <span class="title">欢迎</span>
            <!-- <span> 没有账号? </span>
            <el-button
              @click="
                isRight = true;
                is = 'register';
              "
              >去注册</el-button
            > -->
          </div>
        </div>
      </div>
      <div class="top" :class="{ isRight: isRight }">
        <el-form
          :model="loginRuleForm"
          :rules="rules"
          ref="loginRuleForm"
          class="login"
          :class="{ isShow: is === 'userLogin' || is === 'adminLogin' }"
        >
          <!-- <span class="title" :class="{ isShow: is === 'userLogin' }">
            用户登录
          </span> -->
          <span class="title" :class="{ isShow: is === 'adminLogin' }">
            管理登录
          </span>
          <!-- <div class="tab">
            <span
              :class="{ isActive: is === 'userLogin' }"
              @click="is = 'userLogin'"
            >
              用户登录
            </span>
            <span
              :class="{ isActive: is === 'adminLogin' }"
              @click="is = 'adminLogin'"
            >
              管理登录
            </span>
          </div> -->
          <el-form-item prop="username">
            <el-input placeholder="用户名" v-model="loginRuleForm.username" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              placeholder="密码"
              type="password"
              autocomplete="off"
              @keyup.enter.native="login"
              v-model="loginRuleForm.password"
              show-password
            />
          </el-form-item>
          <div class="checkbox">
            <el-checkbox v-model="rememberPwd">记住密码</el-checkbox>
            <span @click="is = 'forget'">忘记密码</span>
          </div>
          <el-button @click="login">登录</el-button>
        </el-form>

        <el-form
          :model="forgetPasswordRuleForm"
          :rules="rules"
          ref="forgetPasswordRuleForm"
          class="forget"
          :class="{ isShow: is === 'forget' }"
        >
          <span class="title">忘记密码</span>
          <el-form-item prop="username">
            <el-input
              placeholder="用户名"
              v-model="forgetPasswordRuleForm.username"
            />
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              placeholder="邮箱"
              v-model="forgetPasswordRuleForm.email"
            />
          </el-form-item>
          <el-form-item prop="newPassword">
            <el-input
              placeholder="新密码"
              type="password"
              autocomplete="off"
              v-model="forgetPasswordRuleForm.newPassword"
              show-password
            />
          </el-form-item>
          <el-form-item prop="checkPassword">
            <el-input
              placeholder="确认密码"
              type="password"
              autocomplete="off"
              @keyup.enter.native="login"
              v-model="forgetPasswordRuleForm.checkPassword"
              show-password
            />
          </el-form-item>
          <el-form-item prop="code" class="codeBox">
            <el-input
              placeholder="验证码"
              v-model="forgetPasswordRuleForm.code"
              @keyup.enter.native="forgetPassword"
            ></el-input>
            <!-- @keyup.enter.native="forgetPassword" -->
            <el-button @click="getCode" class="code">获取验证码</el-button>
          </el-form-item>

          <!-- <div class="codeBox"></div> -->
          <div class="checkbox">
            <span @click="is = 'userLogin'">去登陆</span>
          </div>
          <el-button @click="forgetPassword">找回密码</el-button>
        </el-form>

        <el-form
          :model="registerRuleForm"
          :rules="rules"
          ref="registerRuleForm"
          class="register"
          :class="{ isShow: is === 'register' }"
        >
          <span class="title">注册</span>
          <el-form-item prop="username">
            <el-input
              placeholder="用户名"
              v-model="registerRuleForm.username"
            />
          </el-form-item>
          <el-form-item prop="email">
            <el-input placeholder="邮箱" v-model="registerRuleForm.email" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              placeholder="密码"
              type="password"
              autocomplete="off"
              v-model="registerRuleForm.password"
              show-password
            />
          </el-form-item>
          <el-form-item prop="checkPassword">
            <el-input
              placeholder="确认密码"
              type="password"
              autocomplete="off"
              @keyup.enter.native="login"
              v-model="registerRuleForm.checkPassword"
              show-password
            />
          </el-form-item>
          <el-form-item prop="code" class="codeBox">
            <el-input
              placeholder="验证码"
              v-model="registerRuleForm.code"
              @keyup.enter.native="register"
            >
            </el-input>
            <!-- @keyup.enter.native="register" -->
            <el-button @click="getCode" class="code">获取验证码</el-button>
          </el-form-item>

          <!-- <div></div> -->
          <!-- <div class="checkbox">
            <span @click="is = 'userLogin'">去登陆</span>
          </div> -->
          <el-button @click="register">注册</el-button>
        </el-form>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      isRight: false,
      isActive: true,
      isForget: false,
      rememberPwd: false,
      is: "adminLogin",
      // username: null,
      // password: null,
      // newPassword: null,
      // checkPassword: null,
      // email: null,
      // code: null,
      // codeId: null,
      ruleForm: {
        username: null,
        password: null,
        newPassword: null,
        checkPassword: null,
        email: null,
        code: null,
        codeId: null,
      },
      loginRuleForm: {
        username: null,
        password: null,
      },
      registerRuleForm: {
        username: null,
        password: null,
        checkPassword: null,
        email: null,
        code: null,
        codeId: null,
      },
      forgetPasswordRuleForm: {
        username: null,
        newPassword: null,
        checkPassword: null,
        email: null,
        code: null,
        codeId: null,
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: ["blur", "change"],
          },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: ["blur", "change"],
          },
        ],
        checkPassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: ["blur", "change"],
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            min: 1,
            max: 8,
            message: "长度在 1 到 8 个字符",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("login", [
      "codeId",
      "message",
      "status",
      "isUserLogin",
      "isAdminLogin",
      "loginStatus",
    ]),
  },
  methods: {
    ...mapActions("login", {
      getCodeId: "getCodeId",
      userLogin: "userLogin",
      adminLogin: "adminLogin",
      userRegister: "userRegister",
      forgetPassowrd: "forgetPassowrd",
    }),
    submitForm(formName) {
      let result;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return (result = true);
        } else {
          return (result = false);
        }
      });
      return result;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getCode() {
      if (this.is === "register" && this.registerRuleForm.email) {
        this.getCodeId({ email: this.registerRuleForm.email }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      } else if (this.is === "forget" && this.forgetPasswordRuleForm.email) {
        this.getCodeId({ email: this.forgetPasswordRuleForm.email }).then(
          () => {
            if (this.status == 200) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.message,
              });
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: this.message,
              });
            }
          }
        );
      } else {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请先输入邮箱!",
        });
      }
    },
    login() {
      // console.log(this.submitForm("loginRuleForm"));
      if (this.submitForm("loginRuleForm")) {
        if (this.rememberPwd) {
          localStorage.setItem("rememberPwd", this.rememberPwd);
          localStorage.setItem("username", this.loginRuleForm.username);
          localStorage.setItem("password", this.loginRuleForm.password);
        } else {
          localStorage.removeItem("rememberPwd");
          localStorage.removeItem("username");
          localStorage.removeItem("password");
        }
        if (this.is === "userLogin") {
          // console.log("userLogin");
          this.userLogin({
            username: this.loginRuleForm.username,
            password: this.loginRuleForm.password,
          }).then(() => {
            if (
              this.isUserLogin &&
              (this.status == 200 || this.loginStatus == 200)
            ) {
              // this.$message({
              //   showClose: true,
              //   type: "success",
              //   message: this.message,
              // });
              this.$router.push("/home");
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: this.message,
              });
            }
          });
        } else {
          // console.log("adminLogin");
          this.adminLogin({
            username: this.loginRuleForm.username,
            password: this.loginRuleForm.password,
          }).then(() => {
            if (
              this.isAdminLogin &&
              (this.status == 200 || this.loginStatus == 200)
            ) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.message,
              });
              setTimeout(this.$router.push("/backstage"), 500);
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: this.message,
              });
            }
          });
        }
      } else {
        this.$message({
          showClose: true,
          type: "error",
          message: "请正确填写用户名和密码!",
        });
      }
    },
    register() {
      if (this.submitForm("registerRuleForm")) {
        this.userRegister({
          username: this.registerRuleForm.username,
          password: this.registerRuleForm.password,
          email: this.registerRuleForm.email,
          checkPassword: this.registerRuleForm.checkPassword,
          code: this.registerRuleForm.code,
          codeId: this.codeId,
        }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.isRight = false;
            this.loginRuleForm.username = this.registerRuleForm.username;
            this.loginRuleForm.password = this.registerRuleForm.password;
            this.is = "userLogin";
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      } else {
        this.$message({
          showClose: true,
          type: "error",
          message: "请正确填写注册表单!",
        });
      }
    },
    forgetPassword() {
      if (this.submitForm("forgetPasswordRuleForm")) {
        this.forgetPassowrd({
          username: this.forgetPasswordRuleForm.username,
          password: this.forgetPasswordRuleForm.newPassword,
          checkPassword: this.forgetPasswordRuleForm.checkPassword,
          email: this.forgetPasswordRuleForm.email,
          code: this.forgetPasswordRuleForm.code,
          codeId: this.codeId,
        }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.is = "userLogin";
            this.loginRuleForm.username = this.forgetPasswordRuleForm.username;
            this.loginRuleForm.password =
              this.forgetPasswordRuleForm.newPassword;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      } else {
        this.$message({
          showClose: true,
          type: "error",
          message: "请正确填写注册表单!",
        });
      }
    },
  },
  mounted() {
    if (localStorage.getItem("rememberPwd")) {
      this.rememberPwd = true;
      this.loginRuleForm.username = localStorage.getItem("username");
      this.loginRuleForm.password = localStorage.getItem("password");
    }
  },
};
</script>

<style lang="less" scoped>
@activeColor: #87cac9;
.el-container {
  // 100%窗口高度
  height: 100vh;
  // 弹性布局 居中
  display: flex;
  justify-content: center;
  align-items: center;
  // background: linear-gradient(200deg, #e3c5eb, #a9c1ed);
  // background: linear-gradient(200deg, #324B4F, #a9c1ed);
  background: linear-gradient(200deg, #17525c, #61815a);
  // 溢出隐藏
  overflow: hidden;
  // .backgroundImg {
  //   position: fixed;
  //   top: 0;
  //   z-index: -999;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   -ms-transform: translateX(-50%);
  //   -webkit-transform: translateX(-50%);
  //   img {
  //     width: 100%;
  //     height: 34.375rem;
  //   }
  // }
  .el-main {
    // background: #9ca5a4;
    height: 46.25rem;
    min-width: 65rem;
    max-width: 65rem;
    display: flex;
    overflow: hidden;
    align-items: center;
    .bottom {
      // background: #9ca5a4;
      height: 34.375rem;
      min-width: 62.5rem;
      max-width: 62.5rem;
      display: flex;
      overflow: hidden;
      padding: 0;
      border-radius: 0.3125rem;
      // box-shadow: 0.3125rem 0.3125rem 0.625rem #737072;
      .left,
      .right {
        height: 34.375rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
        align-items: center;
        min-width: 31.25rem;
        .backgroundImg {
          // position: relative;
          // top: 0;
          // z-index: -999;
          // left: 50%;
          // transform: translateX(-50%);
          transform: translateY(17.5rem);
          // -ms-transform: translateX(-50%);
          // -webkit-transform: translateX(-50%);
          img {
            width: 100%;
            min-height: 34.375rem;
          }
        }
        .content {
          min-height: 34.375rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          color: #fff;
          // transform: translateY(-100%);
          transform: translateY(-16.25rem);
          .title {
            font-size: 1.75rem;
          }
          .el-button {
            color: #3e838a;
            // margin: 3.125rem 0 0 0;
            width: 15rem;
            height: 3.125rem;
            border-radius: 0.625rem;
            border: 0.0625rem solid #ffffff;
            font-size: 1rem;
            font-weight: 550;
            letter-spacing: 0.25rem;
            transition: 0.3s;
            // transform: translateY(-120%);
            &:hover {
              color: #fff;
              background: #656f75;
              opacity: 0.8;
            }
          }
        }
      }
      // .left {
      //   // background: #33bbab;
      // }
      // .right {
      //   // background: #8aa252;
      // }
    }
    .isShow {
      display: flex;
    }
    .top {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      overflow: hidden;
      align-items: center;
      height: 43.75rem;
      min-width: 28.125rem;
      background: #09636d;
      transform: translateX(-59.375rem);
      border-radius: 0.3125rem;
      box-shadow: 0.0625rem 0.0625rem 0.625rem #737072;
      transition: 0.6s;
      .login,
      .forget,
      .register {
        display: none;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
        align-items: center;
        text-align: center;
        .title {
          text-align: center;
          letter-spacing: 0.25rem;
          font-size: 1.75rem;
          font-weight: 550;
          margin: 1.875rem;
          transition: 0.3s;
        }
        /deep/ .el-input__inner {
          &::-webkit-input-placeholder {
            color: #fff;
          }
          color: #fff;
          height: 2.5rem;
          width: 15rem;
          margin: 0.3125rem 0;
          border: none;
          border-radius: 0;
          border-bottom: 0.0625rem solid #fff;
          background: none !important;
          outline: none;
        }
        /deep/ .el-input {
          .el-icon-view:before {
            color: #fff;
            transition: 0.3s;
          }
          .el-icon-view:hover:before {
            color: @activeColor;
          }
        }
        .checkbox {
          width: 15.25rem;
          margin: 0.875rem 0;
          display: flex;
          justify-content: space-between;
          /deep/ .el-checkbox {
            transition: 0.1s;
            color: #fff;
            &:hover {
              color: @activeColor;
            }
            .el-checkbox__input.is-focus .el-checkbox__inner {
              border-color: @activeColor;
            }
            .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: @activeColor;
              border-color: @activeColor;
            }
            .el-checkbox__input.is-checked + .el-checkbox__label {
              color: @activeColor;
            }
          }
          span {
            font-size: 0.875rem;
            transition: 0.1s;
            cursor: pointer;
            &:hover {
              color: @activeColor;
            }
          }
        }
        .el-button {
          color: #3e838a;
          margin: 3.125rem 0;
          width: 15rem;
          height: 3.125rem;
          border-radius: 0.625rem;
          border: 0.0625rem solid #fff;
          font-size: 1rem;
          font-weight: 550;
          letter-spacing: 0.25rem;
          transition: 0.3s;
          &:hover {
            color: #fff;
            background: #656f75;
            opacity: 0.8;
          }
        }
      }
      .login {
        .title {
          display: none;
        }
        .isShow {
          display: flex;
        }
        .tab {
          height: 3rem;
          margin: 0.625rem;

          span {
            cursor: pointer;
            display: inline-block;
            font-size: 1rem;
            border-bottom: 0.0625rem solid #fff;
            padding: 1.25rem 1.25rem 0.3125rem 1.25rem;
            &:hover {
              padding-bottom: 0.25rem;
              color: @activeColor;
              border-bottom: 0.1875rem solid @activeColor;
            }
          }
          .isActive {
            padding-bottom: 0.25rem;
            color: @activeColor;
            border-bottom: 0.1875rem solid @activeColor;
          }
        }
      }
      .forget,
      .register {
        .codeBox {
          width: 15rem;
          display: flex;
          // overflow: hidden;
          /deep/ .el-input {
            width: 7.5rem;
            .el-input__inner {
              width: 7.5rem;
            }
          }
          .code {
            color: #3e838a;
            margin: 0;
            width: 7.5rem;
            height: 2.5rem;
            border-radius: 3.125rem;
            border: 0.0625rem solid #fff;
            font-size: 0.875rem;
            letter-spacing: 0.0625rem;
            margin: 0.3125rem 0;
            &:hover {
              color: #fff;
              background: #656f75;
              opacity: 0.8;
            }
          }
        }
        .checkbox {
          flex-direction: row-reverse;
        }
      }
      .isShow {
        display: flex;
      }
    }
    .isRight {
      transform: translateX(-31.25rem);
      background: #3c623e;
    }
  }
}
</style>

